
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
})
export default class DetailSite extends Vue {
	@Prop() private siteAddressType!: string;
	@Prop() private siteName!: string;
	@Prop() private siteSiret!: string;
	@Prop() private siteAddressLine1!: string;
	@Prop() private siteAddressLine2!: string;
	@Prop() private siteAddressLine3!: string;
	@Prop() private sitePostalCode!: string;
	@Prop() private siteTown!: string;

}
