
import {Component, Vue} from 'vue-property-decorator';
import SigH4Title from '@/components/user/SigH4Title.vue';
import {services} from '@/main';
import DetailSite from '@/components/ficheBilan/DetailSite.vue';
import DetailOrder from '@/components/ficheBilan/DetailOrder.vue';
import NumberUtils from '@/utils/NumberUtils';
import {IUsersServicesSumUpCommand} from '@/services/dto/IUsersServicesSumUpCommand';
import DisclaimerVisio from '@/components/ficheBilan/DisclaimerVisio.vue';

@Component({
  components: {DisclaimerVisio, DetailOrder, DetailSite, SigH4Title},
})
export default class FbUserServicesTab extends Vue {

  private nbComptesUtilisateurs: string = '';
  private listeService: IUsersServicesSumUpCommand[] = [];
  private isBusy: boolean = true;

  private created() {

    // dans cette methode il y a le positionnement de flag pour categoriser les services
    // et rendre la page plus maintenable
    const manageServices = (response: any) => {
      let indexe: number = 0;
      for (const service of response.data) {
        service.index = indexe++;
        if(service.serviceName.toLowerCase().indexOf('vpn') !== -1 || service.serviceName.toLowerCase().indexOf('ballade') !== -1){
            service.isVpnBalade = true;
        }
        if (service.serviceName.toLowerCase().indexOf('messagerie') !== -1) {
          service.isAdnov = false;
          service.isVisio = false;
        } else if (service.serviceName.toLowerCase().indexOf('visio') !== -1) {
          service.isAdnov = true;
          service.isVisio = true;
        } else {
          service.isAdnov = true;
          service.isVisio = false;
        }
        this.listeService.push(service);
      }
    };
    // on charge les informations des comptes utilisateurs
    // d'ailleurs la premiere interaction est vraiment pas optimise :
    // on remonte tous les utilisateurs actifs pour les compter...
    services.restService.callGet('/api/v1/office/current/users')
        .then((response: any) => { this.nbComptesUtilisateurs = response.data.length; });

    services.restService.callGet('/api/v1/office/current/user-services')
        .then(manageServices)
        .finally(() => {
          this.isBusy = false;
        });

  }

  /**
   * Affichage prix total sur 2 digits
   * @private
   */
  private formatToDigit(val: number) {
    return NumberUtils.formatToDigit(val);
  }

  private showServicesList(service: IUsersServicesSumUpCommand) {
    if(Vue.prototype.isAll()){
      return true;
    }else{
      if(Vue.prototype.isAdnov() && service.isAdnov) return true;
      if(Vue.prototype.isAdsn() && !service.isAdnov) return true;
    }
  }

}
