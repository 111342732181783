
import {Component, Ref, Vue} from 'vue-property-decorator';
import {services} from '@/main';
import AlertService from '@/utils/AlertService';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import {IModificationRaisonSociale} from '@/services/dto/IModificationRaisonSociale';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import ErrorUtils from '@/utils/ErrorUtils';

@Component({
  components: {SigInputTexte},
})
export default class FbUpdateCodecCompanyname extends Vue {

  private modificationRaisonSociale: IModificationRaisonSociale = {} as IModificationRaisonSociale;
  private isLoading: boolean = true;
  private modalShow: boolean = false;
  private inputInvalid: boolean = false;

  @Ref('refPhysicalDeliveryOfficeName') private refPhysicalDeliveryOfficeName!: SigInputTexte;


  /**
   * Fonction qui recupere les informations sur la modification de raison sociale
   */
  public modifierRaisonSociale(codecId: number) {
    this.modalShow = true;
    services.restService.callGet('/api/v1/codec/' + codecId + '/physicalDeliveryOfficeName')
        .then((response: any) => {
          this.modificationRaisonSociale = {
            identifiantSalle: '', raisonSocialActuelle: '',
            raisonSocialCible: '', codecId: 0,
          };
          this.modificationRaisonSociale.identifiantSalle = response.data.codecDisplayName;
          this.modificationRaisonSociale.raisonSocialActuelle = response.data.actualValue;
          this.modificationRaisonSociale.raisonSocialCible = response.data.actualValue;
          this.modificationRaisonSociale.codecId = codecId;
          this.refPhysicalDeliveryOfficeName.setValue(response.data.actualValue);
        })
        .catch((respError) => {
          this.modalShow = false;
          console.error(respError.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
  }

  private watchInputPhysicalDelivery(val: ISigInputTextDto) {
    this.inputInvalid = val.hasError;
    this.modificationRaisonSociale.raisonSocialCible = val.value;
  }

  private closeModal(): void {
    this.modificationRaisonSociale = {} as IModificationRaisonSociale;
    this.modalShow = false;
    this.isLoading = true;
    this.refPhysicalDeliveryOfficeName.setValue('');
  }

  /**
   * Fonction qui envoi les informations sur la modification de raison sociale
   */
  private envoiModificationRaisonSociale() {
    this.isLoading = true;
    services.restService.callRawPost('/api/v1/codec/' + this.modificationRaisonSociale.codecId + '/updatePhysicalDeliveryOfficeName',
        this.modificationRaisonSociale.raisonSocialCible)
        .then((response: any) => {
          AlertService.success('La raison sociale a été mise à jour avec succès', 'OK');
        })
        .catch((respError) => {
          console.error(respError.data);
          ErrorUtils.showErrors(respError.data,
              'Une erreur est survenue lors de la mise à jour de la raison sociale du codec : ');

        })
        .finally(() => {
          this.closeModal();
        });
  }

}
