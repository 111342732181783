
export default class NumberUtils {

    /**
     * Permet le formatage d'un number en string sur 2 digits
     */
    public static formatToDigit(value: number): string {
        return value.toLocaleString(undefined, {minimumFractionDigits: 2});
    }

}
