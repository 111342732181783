
import {Component, Ref, Vue} from 'vue-property-decorator';
import {services} from '@/main';
import AlertService from '@/utils/AlertService';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import {IOfficeContactDto} from '@/services/dto/IOfficeContactDto';
import PatternUtils from '@/utils/PatternUtils';
import ErrorUtils from '@/utils/ErrorUtils';

@Component({
  components: {SigInputTexte},
})
export default class FbUpdateContactSsi extends Vue {

  private contactSsii: IOfficeContactDto = {} as IOfficeContactDto;
  private isLoading: boolean = true;
  private infoSection: boolean = true;
  private patternUtils = PatternUtils;

  @Ref('refContSsiTitle') private refContSsiTitle!: SigInputTexte;
  @Ref('refContSsiFirstName') private refContSsiFirstName!: SigInputTexte;
  @Ref('refContSsiLastName') private refContSsiLastName!: SigInputTexte;
  @Ref('refContSsiEmail') private refContSsiEmail!: SigInputTexte;
  @Ref('refContSsiPhone') private refContSsiPhone!: SigInputTexte;
  @Ref('refContSsiMobilePhone') private refContSsiMobilePhone!: SigInputTexte;
  @Ref('refContSsiFax') private refContSsiFax!: SigInputTexte;

  private showUpdateForm(): void {
    this.infoSection = false;
    this.refContSsiTitle.setValue(this.contactSsii.title);
    this.refContSsiFirstName.setValue(this.contactSsii.firstName);
    this.refContSsiLastName.setValue(this.contactSsii.lastName);
    this.refContSsiEmail.setValue(this.contactSsii.email);
    this.refContSsiPhone.setValue(this.contactSsii.phone);
    this.refContSsiMobilePhone.setValue(this.contactSsii.mobilePhone);
    this.refContSsiFax.setValue(this.contactSsii.fax);
  }

  private isFormValid(): boolean {
    const validChamps: boolean[] = [];
    validChamps.push(this.refContSsiTitle !== undefined && this.refContSsiTitle.val.hasError);
    validChamps.push(this.refContSsiFirstName !== undefined && this.refContSsiFirstName.val.hasError);
    validChamps.push(this.refContSsiLastName !== undefined && this.refContSsiLastName.val.hasError);
    validChamps.push(this.refContSsiEmail !== undefined && this.refContSsiEmail.val.hasError);
    validChamps.push(this.refContSsiPhone !== undefined && this.refContSsiPhone.val.hasError);
    validChamps.push(this.refContSsiMobilePhone !== undefined && this.refContSsiMobilePhone.val.hasError);
    validChamps.push(this.refContSsiFax !== undefined && this.refContSsiFax.val.hasError);
    return validChamps.some((x) => x);
  }

  private created() {
    services.restService.callGet('/api/v1/office/current/contact/ssii')
        .then((response: any) => {
          this.contactSsii = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
  }

  /**
   * Fonction qui envoi les informations sur la modification du contact technique
   */
  private envoiModificationContactSSII() {
    this.isLoading = true;
    const dataUpdate: IOfficeContactDto = this.getDataToPost();
    services.restService.callPost('/api/v1/office/current/contact/ssii', dataUpdate)
        .then((response: any) => {
          AlertService.success('Votre demande a bien été prise en compte.');
          this.contactSsii = dataUpdate;
          this.infoSection = true;
        })
        .catch((respError) => {
          ErrorUtils.showErrors(respError.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
  }

  private getDataToPost(): IOfficeContactDto {
    return {
      title: this.refContSsiTitle.val.value,
      firstName: this.refContSsiFirstName.val.value,
      lastName: this.refContSsiLastName.val.value,
      email: this.refContSsiEmail.val.value,
      phone: this.refContSsiPhone.val.value,
      mobilePhone: this.refContSsiMobilePhone.val.value,
      fax: this.refContSsiFax.val.value,
    };
  }

}
