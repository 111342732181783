
import {Component, Ref, Vue} from 'vue-property-decorator';
import {services} from '@/main';
import AlertService from '@/utils/AlertService';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import {IOfficeContactDto} from '@/services/dto/IOfficeContactDto';
import {CloneUtils} from '@/services/CloneUtils';
import PatternUtils from '@/utils/PatternUtils';
import ErrorUtils from '@/utils/ErrorUtils';

@Component({
  components: {SigInputTexte},
})
export default class FbUpdateContactTech extends Vue {

  private titres: any = [
    { value: 'Madame', text: 'Madame' },
    { value: 'Mademoiselle', text: 'Mademoiselle' },
    { value: 'Monsieur', text: 'Monsieur' }];

  private contactTechnique: IOfficeContactDto = {} as IOfficeContactDto;
  private isLoading: boolean = true;
  private infoSection: boolean = true;
  private patternUtils = PatternUtils;

  private title: string = 'Madame';
  @Ref('refContTechFirstName') private refContTechFirstName!: SigInputTexte;
  @Ref('refContTechLastName') private refContTechLastName!: SigInputTexte;
  @Ref('refContTechEmail') private refContTechEmail!: SigInputTexte;
  @Ref('refContTechPhone') private refContTechPhone!: SigInputTexte;
  @Ref('refContTechMobilePhone') private refContTechMobilePhone!: SigInputTexte;
  @Ref('refContTechFax') private refContTechFax!: SigInputTexte;

  private showUpdateForm(): void {
    this.infoSection = false;
    this.title  = this.contactTechnique.title;
    this.refContTechFirstName.setValue(this.contactTechnique.firstName);
    this.refContTechLastName.setValue(this.contactTechnique.lastName);
    this.refContTechEmail.setValue(this.contactTechnique.email);
    this.refContTechPhone.setValue(this.contactTechnique.phone);
    this.refContTechMobilePhone.setValue(this.contactTechnique.mobilePhone);
    this.refContTechFax.setValue(this.contactTechnique.fax);
  }

  private isFormValid(): boolean {
    const validChamps: boolean[] = [];
    validChamps.push(this.refContTechFirstName !== undefined && this.refContTechFirstName.val.hasError);
    validChamps.push(this.refContTechLastName !== undefined && this.refContTechLastName.val.hasError);
    validChamps.push(this.refContTechEmail !== undefined && this.refContTechEmail.val.hasError);
    validChamps.push(this.refContTechPhone !== undefined && this.refContTechPhone.val.hasError);
    validChamps.push(this.refContTechMobilePhone !== undefined && this.refContTechMobilePhone.val.hasError);
    validChamps.push(this.refContTechFax !== undefined && this.refContTechFax.val.hasError);
    return validChamps.some((x) => x);
  }

  private created() {
    // on charge les onglets des contacts techniques et ssii
    services.restService.callGet('/api/v1/office/current/contact/technical').then((response: any) => {
      this.contactTechnique = CloneUtils.deepClone(response.data);
      this.infoSection = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  /**
   * Fonction qui envoi les informations sur la modification du contact technique
   */
  private envoiModificationContactTechnique() {
    this.isLoading = true;
    const dataUpdate: IOfficeContactDto = this.getDataToPost();
    services.restService.callPost('/api/v1/office/current/contact/technical', dataUpdate)
        .then((response: any) => {
          AlertService.success('Votre demande a bien été prise en compte.');
          this.contactTechnique = dataUpdate;
          this.infoSection = true;
        })
        .catch((respError) => {
          ErrorUtils.showErrors(respError.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
  }

  private getDataToPost(): IOfficeContactDto {
    return {
      title: this.title,
      firstName: this.refContTechFirstName.val.value,
      lastName: this.refContTechLastName.val.value,
      email: this.refContTechEmail.val.value,
      phone: this.refContTechPhone.val.value,
      mobilePhone: this.refContTechMobilePhone.val.value,
      fax: this.refContTechFax.val.value,
    };
  }

}
