
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class SigH4Title extends Vue {
  @Prop() private text!: string;
  @Prop() private addclass!: string;
  @Prop() private icnType!: string;

  public showAdsn(): boolean {
    return this.icnType !== undefined && this.icnType.toUpperCase() === 'ADSN';
  }

  public showAdnov(): boolean {
    return this.icnType !== undefined && this.icnType.toUpperCase() === 'ADNOV';
  }
}
