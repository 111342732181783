
import {Component, Prop, Vue} from 'vue-property-decorator';
import {IOrderResponse} from '@/services/dto/IOrderResponse';
import SigIconOrderNetworkState from '@/components/ficheBilan/SigIconOrderNetworkState.vue';
import NumberUtils from '@/utils/NumberUtils';

@Component({
  components: {SigIconOrderNetworkState},
})
export default class DetailOrder extends Vue {
	@Prop() private order!: IOrderResponse;

  private get formatSubscriptionPrice(): string {
    return NumberUtils.formatToDigit(this.order.subscriptionPrice);
  }
}
