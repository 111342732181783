
import {Component, Ref, Vue} from 'vue-property-decorator';
import {services} from '@/main';
import {IOfficeDto} from '@/services/dto/IOfficeDto';
import {ISiteDto} from '@/services/dto/ISiteDto';
import AlertService from '@/utils/AlertService';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import {CloneUtils} from '@/services/CloneUtils';
import ErrorUtils from '@/utils/ErrorUtils';
import PatternUtils from '@/utils/PatternUtils';

@Component({
  components: {SigInputTexte},
})
export default class FbUpdateSitePrincipal extends Vue {

  private patternUtils = PatternUtils;
  private office: IOfficeDto = {} as IOfficeDto;
  private mainSite: ISiteDto = {
    id: -1,
    town: '',
    phone: '',
    postalCode: '',
    siret: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    fax: '',
    name: '',
    networkType: '',
    ndi: '',
    addressType: '',
    hasContactTechnique: false,
  };
  private isLoading: boolean = true;
  private infoSection: boolean = true;

  @Ref('refMainSiteName') private refMainSiteName!: SigInputTexte;
  @Ref('refmainSiteAddressLine1') private refmainSiteAddressLine1!: SigInputTexte;
  @Ref('refmainSiteAddressLine2') private refmainSiteAddressLine2!: SigInputTexte;
  @Ref('refMainSiteaddressLine3') private refMainSiteaddressLine3!: SigInputTexte;
  @Ref('refmainSitePostalCode') private refmainSitePostalCode!: SigInputTexte;
  @Ref('refmainSiteTown') private refmainSiteTown!: SigInputTexte;
  @Ref('refmainSiteSiret') private refmainSiteSiret!: SigInputTexte;
  @Ref('refmainSitePhone') private refmainSitePhone!: SigInputTexte;
  @Ref('refmainSiteFax') private refmainSiteFax!: SigInputTexte;

  private showUpdateForm(): void {
    this.infoSection = false;
    this.refMainSiteName.setValue(this.mainSite.name);
    this.refmainSiteAddressLine1.setValue(this.mainSite.addressLine1);
    this.refmainSiteAddressLine2.setValue(this.mainSite.addressLine2);
    this.refMainSiteaddressLine3.setValue(this.mainSite.addressLine3);
    this.refmainSitePostalCode.setValue(this.mainSite.postalCode);
    this.refmainSiteTown.setValue(this.mainSite.town);
    this.refmainSiteSiret.setValue(this.mainSite.siret);
    this.refmainSitePhone.setValue(this.mainSite.phone);
    this.refmainSiteFax.setValue(this.mainSite.fax);
  }

  private isFormValid(): boolean {
    const validChamps: boolean[] = [];
    validChamps.push(this.refMainSiteName !== undefined && this.refMainSiteName.val.hasError);
    validChamps.push(this.refmainSiteAddressLine1 !== undefined && this.refmainSiteAddressLine1.val.hasError);
    validChamps.push(this.refmainSiteAddressLine2 !== undefined && this.refmainSiteAddressLine2.val.hasError);
    validChamps.push(this.refMainSiteaddressLine3 !== undefined && this.refMainSiteaddressLine3.val.hasError);
    validChamps.push(this.refmainSitePostalCode !== undefined && this.refmainSitePostalCode.val.hasError);
    validChamps.push(this.refmainSiteTown !== undefined && this.refmainSiteTown.val.hasError);
    validChamps.push(this.refmainSiteSiret !== undefined && this.refmainSiteSiret.val.hasError);
    validChamps.push(this.refmainSitePhone !== undefined && this.refmainSitePhone.val.hasError);
    return validChamps.some((x) => x);
  }

  private created() {
    // on charge l'encart en haut à gauche et l'onglet site principal
    services.restService.callGet('/api/v1/office/current/main-site').then((response: any) => {
      this.office = response.data;
      this.mainSite = this.office.mainSite;
      this.$emit('updateOffice', this.office);
      this.infoSection = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  /**
   * Fonction qui envoi les informations sur la modification du site principal
   */
  private envoiModificationSitePrincipal() {
    this.isLoading = true;
    const dtoPost: IOfficeDto = CloneUtils.deepClone(this.office);
    dtoPost.mainSite.name = this.refMainSiteName.val.value;
    dtoPost.mainSite.addressLine1 = this.refmainSiteAddressLine1.val.value;
    dtoPost.mainSite.addressLine2 = this.refmainSiteAddressLine2.val.value;
    dtoPost.mainSite.addressLine3 = this.refMainSiteaddressLine3.val.value;
    dtoPost.mainSite.postalCode = this.refmainSitePostalCode.val.value;
    dtoPost.mainSite.town = this.refmainSiteTown.val.value;
    dtoPost.mainSite.siret = this.refmainSiteSiret.val.value;
    dtoPost.mainSite.phone = this.refmainSitePhone.val.value;
    dtoPost.mainSite.fax = this.refmainSiteFax.val.value;

    services.restService.callPost('/api/v1/office/current/main-site', dtoPost)
        .then((response: any) => {
          AlertService.success('Votre demande a bien été prise en compte.');
          this.office = dtoPost;
          this.mainSite = this.office.mainSite;
          this.$emit('updateOffice', this.office);
          this.infoSection = true;
        })
        .catch((respError) => {
          ErrorUtils.showErrors(respError.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
  }

}
