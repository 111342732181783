
import {Component, Ref} from 'vue-property-decorator';
import DetailOrder from '@/components/ficheBilan/DetailOrder.vue';
import DisclaimerVisio from '@/components/ficheBilan/DisclaimerVisio.vue';
import DetailSite from '@/components/ficheBilan/DetailSite.vue';
import {IOfficeDto} from '@/services/dto/IOfficeDto';
import {ISiteDto} from '@/services/dto/ISiteDto';
import AbstractComponentVue from '../services/AbstractComponentVue';
import FbUpdateContactTech from '@/components/ficheBilan/FbUpdateContactTech.vue';
import FbUpdateSitePrincipal from '@/components/ficheBilan/FbUpdateSitePrincipal.vue';
import FbUpdateContactSsi from '@/components/ficheBilan/FbUpdateContactSsi.vue';
import FbUpdateCodecCompanyName from '@/components/ficheBilan/FbUpdateCodecCompanyName.vue';
import FbVisioSigIconOrderState from '@/components/ficheBilan/FbVisioSigIconOrderState.vue';
import FbNetworkTab from '@/components/ficheBilan/FbNetworkTab.vue';
import SigH4Title from '@/components/user/SigH4Title.vue';
import FbUserServicesTab from '@/components/ficheBilan/FbUserServicesTab.vue';
import FbVisioTab from '@/components/ficheBilan/FbVisioTab.vue';

@Component({
	components: {
    FbVisioTab,
    FbUserServicesTab,
    SigH4Title,
    FbNetworkTab,
    FbVisioSigIconOrderState,
    FbUpdateCodecCompanyName,
    FbUpdateContactSsi,
    FbUpdateSitePrincipal,
    FbUpdateContactTech,
		DetailOrder,
		DisclaimerVisio,
		DetailSite,
	},
})
export default class Bilan extends AbstractComponentVue {
	private office: IOfficeDto = {} as IOfficeDto;

  @Ref('refUpdateCodecPDON') private refUpdateCodecPDON!: FbUpdateCodecCompanyName;

  private created() {
    this.office = {
      id: 0,
      name: '',
      adonixId: 0,
      networkVersion: '',
      cinp: false,
      groupedClients: [],
      typeResale: '',
      mainSite: {} as ISiteDto,
      resaleClientOperators: [],
    };
  }

  /**
   * Le composant renvoie les informations chargé depuis le webservice afin d'être utilisé dans le Cartouche de
   * l'office.
   * @param newVal Information de l'office du notaire
   */
  private updateOffice(newVal: IOfficeDto) {
    this.office = newVal;
  }

}
