
import {Component, Vue} from 'vue-property-decorator';
import {IOrdersByOfficeResponse} from '@/services/dto/IOrdersByOfficeResponse';
import SigH4Title from '@/components/user/SigH4Title.vue';
import {services} from '@/main';
import DetailSite from '@/components/ficheBilan/DetailSite.vue';
import DetailOrder from '@/components/ficheBilan/DetailOrder.vue';
import NumberUtils from '@/utils/NumberUtils';

@Component({
  components: {DetailOrder, DetailSite, SigH4Title},
})
export default class FbNetworkTab extends Vue {

  private ordersNetwork: IOrdersByOfficeResponse = {} as IOrdersByOfficeResponse;
  private prixTotalRzo: number = 0;
  private listeAssociativeDecompte: any = {};
  private isBusy: boolean = true;

  private created() {
    // dans cette methode il y a surtout le calcul de la somme estimé par mois
    // et le calcul du nombre de rowspan pour la ligne site
    const manageSucces = (response: any) => {
      this.ordersNetwork = response.data;
      for (const site of response.data.sites) {
        let cpt: number = site.orders.length;
        for (const order of site.orders) {
          if (order.state !== 'RESILIATED') {
            this.prixTotalRzo += order.subscriptionPrice;
          }
        }
        for (const bundle of site.orderBundles) {
          for (const order of bundle.orders) {
            if (order.state !== 'RESILIATED') {
              this.prixTotalRzo += order.subscriptionPrice;
            }
            cpt += 1;
          }
        }

        this.listeAssociativeDecompte[site.addressType] = cpt;
      }
    };

    // on charge les commandes rezo, attention ça peut prendre du temps dans les 800ms
    services.restService.callGet('/api/v1/office/current/orders')
        .then(manageSucces)
        .finally(() => {
          this.isBusy = false;
        });

  }

  /**
   * Affichage prix total sur 2 digits
   * @private
   */
  private get totalFormated() {
    return NumberUtils.formatToDigit(this.prixTotalRzo);
  }

}
