
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
})
export default class SigIconOrderNetworkState extends Vue {
  public static SIG_ORDER_STATE = [
    {
      value: 'NONE',
      orderStateLabel: 'Non raccordé',
      orderStateClass: '',
      icon: [],
    }, {
      value: 'CREATED',
      orderStateLabel: 'Activation...',
      orderStateClass: 'bleuEnActivation',
      icon: ['far', 'clock'],
    }, {
      value: 'TO_TRANSMIT',
      orderStateLabel: 'Activation...',
      orderStateClass: 'bleuEnActivation',
      icon: ['far', 'clock'],
    }, {
      value: 'TRANSMIT',
      orderStateLabel: 'Activation...',
      orderStateClass: 'bleuEnActivation',
      icon: ['far', 'clock'],
    }, {
      value: 'RECEIVED',
      orderStateLabel: 'Activation...',
      orderStateClass: 'bleuEnActivation',
      icon: ['fas', 'times'],
    }, {
      value: 'REFUSED',
      orderStateLabel: 'Non raccordé',
      orderStateClass: '',
      icon: ['fas', 'times'],
    }, {
      value: 'ACCEPTED',
      orderStateLabel: 'Activation...',
      orderStateClass: 'bleuEnActivation',
      icon: ['far', 'clock'],
    }, {
      value: 'TO_CANCEL',
      orderStateLabel: 'Désactivation...',
      orderStateClass: 'rougeTransparent',
      icon: ['fas', 'times-circle'],
    }, {
      value: 'CANCELLATION_TRANSMIT',
      orderStateLabel: 'Désactivation...',
      orderStateClass: 'rougeTransparent',
      icon: ['fas', 'times-circle'],
    }, {
      value: 'CANCELLATION_IN_PROGRESS',
      orderStateLabel: 'Désactivation...',
      orderStateClass: 'rougeTransparent',
      icon: ['fas', 'times-circle'],
    }, {
      value: 'CANCELLED',
      orderStateLabel: 'Désactivé',
      orderStateClass: 'state_DELETED',
      icon: ['fas', 'times'],
    }, {
      value: 'TO_RESILIATE',
      orderStateLabel: 'Désactivation...',
      orderStateClass: 'rougeTransparent',
      icon: ['fas', 'times-circle'],
    }, {
      value: 'RESILIATION_TRANSMIT',
      orderStateLabel: 'Désactivation...',
      orderStateClass: 'rougeTransparent',
      icon: ['fas', 'times-circle'],
    }, {
      value: 'RESILIATION_IN_PROGRESS',
      orderStateLabel: 'Désactivation...',
      orderStateClass: 'rougeTransparent',
      icon: ['fas', 'times-circle'],
    }, {
      value: 'RESILIATED',
      orderStateLabel: 'Désactivé',
      orderStateClass: 'rougeResilie',
      icon: ['fas', 'times'],
    }, {
      value: 'INSTALL_IN_PROGRESS',
      orderStateLabel: 'Activation...',
      orderStateClass: 'bleuEnActivation',
      icon: ['far', 'clock'],
    }, {
      value: 'INSTALLED',
      orderStateLabel: 'Activation...',
      orderStateClass: 'bleuEnActivation',
      icon: ['far', 'clock'],
    }, {
      value: 'ACTIVE',
      orderStateLabel: 'Actif',
      orderStateClass: 'vertActif',
      icon: ['fas', 'check'],
    }];

  @Prop() private orderState?: string | null;

  private get buttonIconFromState(): string[] {
    const filter = SigIconOrderNetworkState.SIG_ORDER_STATE.filter((x: any) => x.value === this.orderState,
    );
    return filter.length > 0 ? filter[0].icon : ['fas', 'question'];
  }

  private get buttonLabelFromState(): string {
    const filter = SigIconOrderNetworkState.SIG_ORDER_STATE.filter((x: any) => x.value === this.orderState,
    );
    return filter.length > 0 ? filter[0].orderStateLabel :
        this.orderState !== undefined && this.orderState !== null ? this.orderState : 'undefined';
  }

  private get buttonClassFromState(): string {
    const filter = SigIconOrderNetworkState.SIG_ORDER_STATE.filter((x: any) => x.value === this.orderState,
    );
    return filter.length > 0 ? filter[0].orderStateClass :
        this.orderState !== undefined && this.orderState !== null ? this.orderState : 'undefined';
  }
}
