
import {Component, Ref, Vue} from 'vue-property-decorator';
import SigH4Title from '@/components/user/SigH4Title.vue';
import {services} from '@/main';
import DetailSite from '@/components/ficheBilan/DetailSite.vue';
import DetailOrder from '@/components/ficheBilan/DetailOrder.vue';
import DisclaimerVisio from '@/components/ficheBilan/DisclaimerVisio.vue';
import FbVisioSigIconOrderState from '@/components/ficheBilan/FbVisioSigIconOrderState.vue';
import {IServicesGenCommand} from '@/services/dto/IServicesGenCommand';
import AlertService from '@/utils/AlertService';
import FbUpdateCodecCompanyName from '@/components/ficheBilan/FbUpdateCodecCompanyName.vue';

@Component({
  components: {
    FbUpdateCodecCompanyName, FbVisioSigIconOrderState, DisclaimerVisio, DetailOrder,
    DetailSite, SigH4Title,
  },
})
export default class FbUserServicesTab extends Vue {

  @Ref('refUpdateCodecPDON') private refUpdateCodecPDON!: FbUpdateCodecCompanyName;
  private ordersVisio: IServicesGenCommand[] = [];
  private isBusy: boolean = true;

  private created() {
    // on charge les commandes visio
    services.restService.callGet('/api/v1/office/current/office-orders')
        .then((response: any) => {
          this.ordersVisio = response.data;
        })
        .finally(() => {
          this.isBusy = false;
        });
  }

  /**
   * Fonction qui gere l'appel et l'affichage des popup pour rediffuser le mot de passe
   */
  private rediffuserMotDePasseVisio(codecId: number) {
    this.isBusy = true;
    services.restService.callPost('/api/v1/office/current/services/visio/' + codecId + '/broadcast-credentials',
        {})
        .then((response: any) => {
          AlertService.success('Les identifiants attribués à votre salle de réunion ont bien été ' +
              'envoyés par courriel.');
        })
        .finally(() => {
          this.isBusy = false;
        });
  }

  /**
   * Recherche dans les actions du IServicesGenCommand la présence de l'URL 'codec-update-physicaldeliveryofficename'
   * qui permetra l'affichage du lien de modification du nom de la raison sociale.
   * Attention : vérification importante car des conditions de présence sont définies dans le back.
   */
  private hasInActionsUpdateCompanyName(servGenCmd: IServicesGenCommand): boolean {
    return servGenCmd.actions.some((x) =>
        (x.url !== undefined && x.url !== null && x.url === 'codec-update-physicaldeliveryofficename?'),
    );
  }

  /**
   * Fonction qui recupere les informations sur la modification de raison sociale
   */
  private modifierRaisonSociale(codecId: number) {
    if (this.refUpdateCodecPDON !== undefined) {
      this.refUpdateCodecPDON.modifierRaisonSociale(codecId);
    } else {
      console.error('Module modification raison social introuvable.');
    }
  }

  /**
   * Permet d'afficher le lien de téléchargement de l'attestation d'installation d'équipement de visioconférence
   */
  private canDownloadCertificateVisio(servGenCmd: IServicesGenCommand): boolean {
    return servGenCmd.actions.some((x) =>
        (x.message !== undefined && x.message !== null && x.message === 'Télécharger mon attestation'),
    );
  }
}
